export const forbiddenSearchWords = [
    'iphone', 
    'phone', 
    'mobile phone',
    'obagi',
    'lip',
    'mascara',
    'makeup',
    'beauty',
    'brushes',
    'applicators',
    'hair removal',
    'scissor',
    'shear',
    'skin',
    'fragnance',
    'toothpaste',
    'tooth',
    'knife',
    'knives',
    'grooming',
    'shave',
    'shaving',
    'razor',
    'after sun',
    'sun protect',
    'cream',
    'lotion',
    'gel',
    'shower gel',
    'treatment',
    'treatments',
    'make up',
    'eye care',
    'eyelashes',
    'eyeliner',
    'anti aging',
    'face makeup',
    'bronzer',
    'powder',
    'moisturizer',
    'oil',
    'glitter',
    'lightener',
    'remover',
    'blemish',
    'revitalizer',
    'scrub',
    'scrubs',
    'exfoliants',
    'hair conditioner',
    'hair',
    'hair care',
    'hair shampoo',
    'makeup',
    'bath body',
    'post shave',
    'hair styling',
    'shave cream',
    'tooth whitener',
    'lipstick',
    'cosmetic',
    'serum',
    'perfume',
    'skincare',
    'deodorant',
    'nail polish',
    'nail lacquer',
    'liquid',
    'alcohol',
    'soap',
    'cologne',
    'bath',
    'body cream',
    'clean',
    'loreal',
    'shampoo'
];