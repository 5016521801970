{
    "data": [
        {
            "id": 1,
            "shopstyleId":1000463445,
            "name": "Columbia Men’s Fairbanks™ Omni-Heat™ Boot",
            "price": "6,654.54",
            "apiPrice":"1,660.00",
            "sizeTypes":["standard","wide"],
            "type":"boot",
            "productWebSite":"https://www.columbia.com/p/mens-fairbanks-omni-heat-boot-1746011.html",
            "imgUrl":"https://columbia.scene7.com/is/image/ColumbiaSportswear2/1746011_288_f?wid=768&hei=806&v=1700226314",
            "colors": [
                {
                    "id": "elk&rusty",
                    "name": "Elk, Rusty",
                    "sizeTypes": [
                        "standard",
                        "wide"
                    ],
                    "sizes": [
                        {
                            "value": "8",
                            "sizeType": "standard"
                        },
                        {
                            "value": "9",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "11.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "12",
                            "sizeType": "standard"
                        },
                        {
                            "value": "13",
                            "sizeType": "standard"
                        },
                        {
                            "value": "14",
                            "sizeType": "standard"
                        },
                        {
                            "value": "8.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "9.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "10",
                            "sizeType": "wide"
                        },
                        {
                            "value": "10.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "11.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "12",
                            "sizeType": "wide"
                        }
                    ]
                },
                {
                    "id": "graphite&dark-moss",
                    "name": "Graphite, Dark Moss",
                    "sizeTypes": [
                        "standard",
                        "wide"
                    ],
                    "sizes": [
                        {
                            "value": "7",
                            "sizeType": "standard"
                        },
                        {
                            "value": "7.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "8",
                            "sizeType": "standard"
                        },
                        {
                            "value": "8.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "11.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "12",
                            "sizeType": "standard"
                        },
                        {
                            "value": "13",
                            "sizeType": "standard"
                        },
                        {
                            "value": "14",
                            "sizeType": "standard"
                        },
                        
                        {
                            "value": "7.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "8",
                            "sizeType": "wide"
                        },
                        {
                            "value": "8.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "9",
                            "sizeType": "wide"
                        },
                        {
                            "value": "9.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "10",
                            "sizeType": "wide"
                        },
                        {
                            "value": "10.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "11.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "12",
                            "sizeType": "wide"
                        },
                        
                        {
                            "value": "14",
                            "sizeType": "wide"
                        }
                    ]
                },
                {
                    "id": "abyss&dark-adobe",
                    "name": "Abyss, Dark Adobe",
                    "sizeTypes": [
                        "wide"
                    ],
                    "sizes": [
                       
                        {
                            "value": "7",
                            "sizeType": "wide"
                        },
                        {
                            "value": "8",
                            "sizeType": "wide"
                        },
                        {
                            "value": "9",
                            "sizeType": "wide"
                        },
                        {
                            "value": "10.5",
                            "sizeType": "wide"
                        }
                    ]
                },
                {
                    "id": "black&rusty",
                    "name": "Black Rusty",
                    "sizeTypes": [
                        "standard"
                    ],
                    "sizes": [
                        {
                            "value": "8",
                            "sizeType": "standard"
                        },
                        {
                            "value": "8.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "9",
                            "sizeType": "standard"
                        },
                        {
                            "value": "9.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "11.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "12",
                            "sizeType": "standard"
                        },
                        {
                            "value": "13",
                            "sizeType": "standard"
                        },
                        {
                            "value": "14",
                            "sizeType": "standard"
                        }
                    ]
                },
                {
                    "id": "titanium-2&warm-copper",
                    "name": "Titanium II, Warm Copper",
                    "sizeTypes": [
                        "wide"
                    ],
                    "sizes": [
                        {
                            "value": "7.5",
                            "sizeType": "wide"
                        },
                        {
                            "value": "8",
                            "sizeType": "wide"
                        },
                        {
                            "value": "9.5",
                            "sizeType": "wide"
                        }
                    ]
                }
            ],
            "images": [
                {
                    "filePath": "/assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v1/p1.webp",
                    "colorId": "elk&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v1/p2.webp",
                    "colorId": "elk&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v1/p3.webp",
                    "colorId": "elk&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v1/p4.webp",
                    "colorId": "elk&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v1/p5.webp",
                    "colorId": "elk&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v1/p6.webp",
                    "colorId": "elk&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v1/p7.webp",
                    "colorId": "elk&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v1/p8.webp",
                    "colorId": "elk&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v1/p9.webp",
                    "colorId": "elk&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v2/p1.webp",
                    "colorId": "abyss&dark-adobe"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v2/p2.webp",
                    "colorId": "abyss&dark-adobe"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v2/p3.webp",
                    "colorId": "abyss&dark-adobe"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v2/p4.webp",
                    "colorId": "abyss&dark-adobe"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v2/p5.webp",
                    "colorId": "abyss&dark-adobe"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v2/p6.webp",
                    "colorId": "abyss&dark-adobe"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v2/p7.webp",
                    "colorId": "abyss&dark-adobe"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v2/p8.webp",
                    "colorId": "abyss&dark-adobe"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v2/p9.webp",
                    "colorId": "abyss&dark-adobe"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p1.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p2.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p3.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p4.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p5.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p6.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p7.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p8.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p9.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p10.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v3/p11.webp",
                    "colorId": "black&rusty"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v4/p1.webp",
                    "colorId": "titanium-2&warm-copper"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v4/p2.webp",
                    "colorId": "titanium-2&warm-copper"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v4/p3.webp",
                    "colorId": "titanium-2&warm-copper"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v4/p4.webp",
                    "colorId": "titanium-2&warm-copper"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v4/p5.webp",
                    "colorId": "titanium-2&warm-copper"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v4/p6.webp",
                    "colorId": "titanium-2&warm-copper"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v4/p7.webp",
                    "colorId": "titanium-2&warm-copper"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v4/p8.webp",
                    "colorId": "titanium-2&warm-copper"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v4/p9.webp",
                    "colorId": "titanium-2&warm-copper"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v5/p1.webp",
                    "colorId": "graphite&dark-moss"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v5/p2.webp",
                    "colorId": "graphite&dark-moss"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v5/p3.webp",
                    "colorId": "graphite&dark-moss"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v5/p4.webp",
                    "colorId": "graphite&dark-moss"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v5/p5.webp",
                    "colorId": "graphite&dark-moss"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v5/p6.webp",
                    "colorId": "graphite&dark-moss"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v5/p7.webp",
                    "colorId": "graphite&dark-moss"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v5/p8.webp",
                    "colorId": "graphite&dark-moss"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-fairbanks-omni-heat-boot-1746011/v5/p9.webp",
                    "colorId": "graphite&dark-moss"
                }
            ]
        },
        {
            "id": 2,
            "shopstyleId":1009292407,
            "name": "Columbia Women's Newton Ridge™ BC Boot",
            "price": "6,751.70",
            "apiPrice":"1,750.00",
            "sizeTypes":["standard"],
            "type":"boot",
            "productWebSite":"https://www.columbia.com/p/womens-newton-ridge-bc-boot-2044571.html",
            "imgUrl":"https://columbia.scene7.com/is/image/ColumbiaSportswear2/2044571_252_f_tt?wid=768&hei=806&v=1700226314",
            "colors": [
                {
                    "id": "wet-sand&cedar",
                    "name": "Wet Sand, Cedar",
                    "sizeTypes": [
                        "standard"
                    ],
                    "sizes": [
                        {
                            "value": "5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "5.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "6",
                            "sizeType": "standard"
                        },
                        {
                            "value": "6.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "7",
                            "sizeType": "standard"
                        },
                        {
                            "value": "7.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "8",
                            "sizeType": "standard"
                        },
                        {
                            "value": "8.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "9",
                            "sizeType": "standard"
                        },
                        {
                            "value": "9.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "11",
                            "sizeType": "standard"
                        },
                        {
                            "value": "12",
                            "sizeType": "standard"
                        }
                    ]
                },
                {
                    "id": "ti-titanium&sage-leaf",
                    "name": "Ti Titanium, Sage Leaf",
                    "sizeTypes": [
                        "standard"
                    ],
                    "sizes": [
                        {
                            "value": "5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "5.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "6",
                            "sizeType": "standard"
                        },
                        {
                            "value": "6.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "7",
                            "sizeType": "standard"
                        },
                        {
                            "value": "7.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "8",
                            "sizeType": "standard"
                        },
                        {
                            "value": "8.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "9",
                            "sizeType": "standard"
                        },
                        {
                            "value": "9.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10",
                            "sizeType": "standard"
                        },
                        {
                            "value": "10.5",
                            "sizeType": "standard"
                        },
                        {
                            "value": "11",
                            "sizeType": "standard"
                        },
                        {
                            "value": "12",
                            "sizeType": "standard"
                        }
                    ]
                }
            ],
            "images": [
                {
                    "filePath": "/assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v1/p1.webp",
                    "colorId": "wet-sand&cedar"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v1/p2.webp",
                    "colorId": "wet-sand&cedar"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v1/p3.webp",
                    "colorId": "wet-sand&cedar"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v1/p4.webp",
                    "colorId": "wet-sand&cedar"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v1/p5.webp",
                    "colorId": "wet-sand&cedar"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v1/p6.webp",
                    "colorId": "wet-sand&cedar"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v1/p7.webp",
                    "colorId": "wet-sand&cedar"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v1/p8.webp",
                    "colorId": "wet-sand&cedar"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v2/p1.webp",
                    "colorId": "ti-titanium&sage-leaf"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v2/p2.webp",
                    "colorId": "ti-titanium&sage-leaf"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-newton-ridge-bc-boot-2044571/v2/p3.webp",
                    "colorId": "ti-titanium&sage-leaf"
                }
            ]
        },
        {
            "id": 3,
            "shopstyleId":986540231,
            "name": "Columbia Men's Marquam Peak Fusion™ Insulated Parka",
            "price": "9,709.81",
            "apiPrice":"6,236.00",
            "sizeTypes":["standard","big","tall"],
            "type":"jacket",
            "productWebSite":"https://www.columbia.com/p/mens-marquam-peak-fusion-insulated-parka-1957491.html",
            "imgUrl":"https://columbia.scene7.com/is/image/ColumbiaSportswear2/1957491_257_f?wid=768&hei=806&v=1700226314",
            "colors": [
                {
                    "id": "delta&black",
                    "name": "Delta, Black",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "black",
                    "name": "Black",
                    "sizeTypes": [
                        "standard",
                        "big"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        }
                    ]
                },
                {
                    "id": "collegiate-navy",
                    "name": "Collegiate Navy",
                    "sizeTypes": [
                        "standard"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        }
                    ]
                },
                {
                    "id": "warp-red&shark",
                    "name": "Warp Red, Shark",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }

                    ]
                }
            ],
            "images": [
                {
                    "filePath": "/assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p1.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p2.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p3.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p4.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p5.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p6.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p7.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p8.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p9.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p10.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v1/p11.webp",
                    "colorId": "delta&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v2/p1.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v2/p2.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v2/p3.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v2/p4.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v2/p5.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v2/p6.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v2/p7.webp",
                    "colorId": "black"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v3/p1.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v3/p2.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v3/p3.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v3/p4.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v3/p5.webp",
                    "colorId": "collegiate-navy"
                },
       
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v4/p1.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v4/p2.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v4/p3.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v4/p4.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-marquam-peak-fusion-insulated-parka-1957491/v4/p5.webp",
                    "colorId": "warp-red&shark"
                }
            ]
        },
        {
            "id": 4,
            "shopstyleId":792957109,
            "name": "Columbia Men’s Powder Lite™ Hooded Insulated Jacket",
            "price": "6,103.94",
            "apiPrice":"3,235.00",
            "sizeTypes":["standard","big","tall"],
            "type":"jacket",
            "productWebSite":"https://www.columbia.com/p/mens-powder-lite-hooded-insulated-jacket-1693931.html",
            "imgUrl":"https://columbia.scene7.com/is/image/ColumbiaSportswear2/1693931_015_f_om?wid=768&hei=806&v=1700226314",
            "colors": [
                {
                    "id": "shark&city-grey",
                    "name": "Shark, City Grey",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "black-mod-camo-print",
                    "name": "Black Mod Camo Print",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                       
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "collegiate-navy",
                    "name": "Collegiate Navy",
                    "sizeTypes": [
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "black-passages-print",
                    "name": "Black Passages Print",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "dark-stone&shark&stone-green",
                    "name": "Dark Stone, Shark, Stone Green",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },                       
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },                       
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "niagara&metal",
                    "name": "Niagara, Metal",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                       
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "shasta",
                    "name": "Shasta",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "warp-red-pathways-print",
                    "name": "Warp Red Pathways Print",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                       
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "warp-red&shark",
                    "name": "Warp Red, Shark",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                       
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                }
               
            ],
            "images": [
                {
                    "filePath": "/assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v1/p1.webp",
                    "colorId": "shark&city-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v1/p2.webp",
                    "colorId": "shark&city-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v1/p3.webp",
                    "colorId": "shark&city-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v1/p4.webp",
                    "colorId": "shark&city-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v1/p5.webp",
                    "colorId": "shark&city-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v1/p6.webp",
                    "colorId": "shark&city-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v1/p7.webp",
                    "colorId": "shark&city-grey"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v2/p1.webp",
                    "colorId": "black-mod-camo-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v2/p2.webp",
                    "colorId": "black-mod-camo-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v2/p3.webp",
                    "colorId": "black-mod-camo-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v2/p4.webp",
                    "colorId": "black-mod-camo-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v2/p5.webp",
                    "colorId": "black-mod-camo-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v2/p6.webp",
                    "colorId": "black-mod-camo-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v2/p7.webp",
                    "colorId": "black-mod-camo-print"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v3/p1.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v3/p2.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v3/p3.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v3/p4.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v3/p5.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v3/p6.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v3/p7.webp",
                    "colorId": "collegiate-navy"
                },
       
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v4/p1.webp",
                    "colorId": "black-passages-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v4/p2.webp",
                    "colorId": "black-passages-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v4/p3.webp",
                    "colorId": "black-passages-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v4/p4.webp",
                    "colorId": "black-passages-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v4/p5.webp",
                    "colorId": "black-passages-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v4/p6.webp",
                    "colorId": "black-passages-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v4/p7.webp",
                    "colorId": "black-passages-print"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v5/p1.webp",
                    "colorId": "dark-stone&shark&stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v5/p2.webp",
                    "colorId": "dark-stone&shark&stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v5/p3.webp",
                    "colorId": "dark-stone&shark&stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v5/p4.webp",
                    "colorId": "dark-stone&shark&stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v5/p5.webp",
                    "colorId": "dark-stone&shark&stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v5/p6.webp",
                    "colorId": "dark-stone&shark&stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v5/p7.webp",
                    "colorId": "dark-stone&shark&stone-green"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v6/p1.webp",
                    "colorId": "niagara&metal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v6/p2.webp",
                    "colorId": "niagara&metal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v6/p3.webp",
                    "colorId": "niagara&metal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v6/p4.webp",
                    "colorId": "niagara&metal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v6/p5.webp",
                    "colorId": "niagara&metal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v6/p6.webp",
                    "colorId": "niagara&metal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v6/p7.webp",
                    "colorId": "niagara&metal"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v7/p1.webp",
                    "colorId": "shasta"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v7/p2.webp",
                    "colorId": "shasta"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v7/p3.webp",
                    "colorId": "shasta"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v7/p4.webp",
                    "colorId": "shasta"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v7/p5.webp",
                    "colorId": "shasta"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v7/p6.webp",
                    "colorId": "shasta"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v7/p7.webp",
                    "colorId": "shasta"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v8/p1.webp",
                    "colorId": "warp-red-pathways-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v8/p2.webp",
                    "colorId": "warp-red-pathways-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v8/p3.webp",
                    "colorId": "warp-red-pathways-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v8/p4.webp",
                    "colorId": "warp-red-pathways-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v8/p5.webp",
                    "colorId": "warp-red-pathways-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v8/p6.webp",
                    "colorId": "warp-red-pathways-print"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v8/p7.webp",
                    "colorId": "warp-red-pathways-print"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v9/p1.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v9/p2.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v9/p3.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v9/p4.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v9/p5.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v9/p6.webp",
                    "colorId": "warp-red&shark"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-powder-lite-hooded-insulated-jacket-1693931/v9/p7.webp",
                    "colorId": "warp-red&shark"
                }


            ]
        },
        {
            "id": 5,
            "shopstyleId":989071767,
            "name": "Columbia Men's Watertight™ II Rain Jacket",
            "price": "4,279.42",
            "apiPrice":"2,295.00",
            "sizeTypes":["standard","big","tall"],
            "type":"jacket",
            "productWebSite":"https://www.columbia.com/p/mens-watertight-ii-rain-jacket-1533891.html",
            "imgUrl":"https://columbia.scene7.com/is/image/ColumbiaSportswear2/1533891_433_f?wid=768&hei=806&v=1700226314",
            "colors": [
                {
                    "id": "bright-indigo",
                    "name": "Bright Indigo",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "canteen&black",
                    "name": "Canteen, Black",
                    "sizeTypes": [
                        "standard",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                       
                    
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "black",
                    "name": "Black",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },
                        
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "spice",
                    "name": "Spice",
                    "sizeTypes": [
                        "standard",
                        "big"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        }
                    ]
                },
                {
                    "id": "skyler&dark-mountain",
                    "name": "Skyler, Dark Mountain",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },       
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },                             
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },                       
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },                       
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },                       
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }

                    ]
                },
                {
                    "id": "columbia-grey",
                    "name": "Columbia Grey",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },
                        
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "graphite",
                    "name": "Graphite",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "stone-green",
                    "name": "Stone Green",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                        
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                       
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },
                        
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "collegiate-navy",
                    "name": "Collegiate Navy",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                       
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                        {
                            "value": "2X",
                            "sizeType": "big"
                        },
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                        {
                            "value": "4X",
                            "sizeType": "big"
                        },
                        {
                            "value": "5X",
                            "sizeType": "big"
                        },
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "4XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                },
                {
                    "id": "mountain-red",
                    "name": "Mountain Red",
                    "sizeTypes": [
                        "standard",
                        "big",
                        "tall"
                    ],
                    "sizes": [
                       
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "big"
                        },
                
                        {
                            "value": "3X",
                            "sizeType": "big"
                        },
                       
                        {
                            "value": "6X",
                            "sizeType": "big"
                        },
                        {
                            "value": "LT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "XLT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "2XT",
                            "sizeType": "tall"
                        },
                        {
                            "value": "3XT",
                            "sizeType": "tall"
                        },
                       
                        {
                            "value": "5XT",
                            "sizeType": "tall"
                        }
                    ]
                }
               
            ],
            "images": [
                {
                    "filePath": "/assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v1/p1.webp",
                    "colorId": "bright-indigo"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v1/p2.webp",
                    "colorId": "bright-indigo"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v1/p3.webp",
                    "colorId": "bright-indigo"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v1/p4.webp",
                    "colorId": "bright-indigo"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v1/p5.webp",
                    "colorId": "bright-indigo"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v1/p6.webp",
                    "colorId": "bright-indigo"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v1/p7.webp",
                    "colorId": "bright-indigo"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v1/p8.webp",
                    "colorId": "bright-indigo"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v2/p1.webp",
                    "colorId": "canteen&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v2/p2.webp",
                    "colorId": "canteen&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v2/p3.webp",
                    "colorId": "canteen&black"
                },
               
                
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v3/p1.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v3/p2.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v3/p3.webp",
                    "colorId": "black"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v4/p1.webp",
                    "colorId": "spice"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v4/p2.webp",
                    "colorId": "spice"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v4/p3.webp",
                    "colorId": "spice"
                },
                

                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v5/p1.webp",
                    "colorId": "skyler&dark-mountain"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v5/p2.webp",
                    "colorId": "skyler&dark-mountain"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v5/p3.webp",
                    "colorId": "skyler&dark-mountain"
                },
               

                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v6/p1.webp",
                    "colorId": "columbia-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v6/p2.webp",
                    "colorId": "columbia-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v6/p3.webp",
                    "colorId": "columbia-grey"
                },
                

                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v7/p1.webp",
                    "colorId": "graphite"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v7/p2.webp",
                    "colorId": "graphite"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v7/p3.webp",
                    "colorId": "graphite"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v8/p1.webp",
                    "colorId": "stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v8/p2.webp",
                    "colorId": "stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v8/p3.webp",
                    "colorId": "stone-green"
                },


                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v9/p1.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v9/p2.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v9/p3.webp",
                    "colorId": "collegiate-navy"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v10/p1.webp",
                    "colorId": "mountain-red"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v10/p2.webp",
                    "colorId": "mountain-red"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/mens-watertight-ii-rain-jacket-1533891/v10/p3.webp",
                    "colorId": "mountain-red"
                }

            ]
        },
        {
            "id": 6,
            "shopstyleId":989143254,
            "name": "Columbia Women's Joy Peak™ Mid Insulated Hooded Jacket",
            "price": "7,842.10",
            "apiPrice":"3,830.00",
            "sizeTypes":["standard","plus"],
            "type":"jacket",
            "productWebSite":"https://www.columbia.com/p/womens-joy-peak-mid-insulated-hooded-jacket-1982661.html",
            "imgUrl":"https://columbia.scene7.com/is/image/ColumbiaSportswear2/1982661_616_f_om?wid=768&hei=806&v=1700226314",
            "colors": [
                {
                    "id": "marionberry",
                    "name": "Marionberry",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "dark-nocturnal",
                    "name": "Dark Nocturnal",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "beetroot",
                    "name": "Beetroot",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "black",
                    "name": "Black",
                    "sizeTypes": [
                        "standard"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        }
                    ]
                },
                {
                    "id": "stone-green",
                    "name": "Stone Green",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }

                    ]
                },
                {
                    "id": "dusty-pink",
                    "name": "Dusty Pink",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "aqua-haze",
                    "name": "Aqua Haze",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "chalk",
                    "name": "Chalk",
                    "sizeTypes": [
                        "plus"
                    ],
                    "sizes": [
                        
                       
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "camel-brown",
                    "name": "Camel Brown",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "city-grey",
                    "name": "City Gray",
                    "sizeTypes": [
                        "standard"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        }
                    ]
                },
                {
                    "id": "night-wave",
                    "name": "Night Wave",
                    "sizeTypes": [
                        "standard"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        }
                    ]
                },
                {
                    "id": "dark-sapphire",
                    "name": "Dark Sapphire",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1x",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3x",
                            "sizeType": "plus"
                        }
                    ]
                }
               
            ],
            "images": [
                {
                    "filePath": "/assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v1/p1.webp",
                    "colorId": "marionberry"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v1/p2.webp",
                    "colorId": "marionberry"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v1/p3.webp",
                    "colorId": "marionberry"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v1/p4.webp",
                    "colorId": "marionberry"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v1/p5.webp",
                    "colorId": "marionberry"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v1/p6.webp",
                    "colorId": "marionberry"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v1/p7.webp",
                    "colorId": "marionberry"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v1/p8.webp",
                    "colorId": "marionberry"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v2/p1.webp",
                    "colorId": "dark-nocturnal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v2/p2.webp",
                    "colorId": "dark-nocturnal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v2/p3.webp",
                    "colorId": "dark-nocturnal"
                },
               
                
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v3/p1.webp",
                    "colorId": "beetroot"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v3/p2.webp",
                    "colorId": "beetroot"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v3/p3.webp",
                    "colorId": "beetroot"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v4/p1.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v4/p2.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v4/p3.webp",
                    "colorId": "black"
                },
                

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v5/p1.webp",
                    "colorId": "stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v5/p2.webp",
                    "colorId": "stone-green"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v5/p3.webp",
                    "colorId": "stone-green"
                },
               

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v6/p1.webp",
                    "colorId": "dusty-pink"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v6/p2.webp",
                    "colorId": "dusty-pink"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v6/p3.webp",
                    "colorId": "dusty-pink"
                },
                

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v7/p1.webp",
                    "colorId": "aqua-haze"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v7/p2.webp",
                    "colorId": "aqua-haze"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v7/p3.webp",
                    "colorId": "aqua-haze"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v8/p1.webp",
                    "colorId": "chalk"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v8/p2.webp",
                    "colorId": "chalk"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v8/p3.webp",
                    "colorId": "chalk"
                },


                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v9/p1.webp",
                    "colorId": "camel-brown"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v9/p2.webp",
                    "colorId": "camel-brown"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v9/p3.webp",
                    "colorId": "camel-brown"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v10/p1.webp",
                    "colorId": "city-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v10/p2.webp",
                    "colorId": "city-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v10/p3.webp",
                    "colorId": "city-grey"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v11/p1.webp",
                    "colorId": "night-wave"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v11/p2.webp",
                    "colorId": "night-wave"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v11/p3.webp",
                    "colorId": "night-wave"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v12/p1.webp",
                    "colorId": "dark-sapphire"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v12/p2.webp",
                    "colorId": "dark-sapphire"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-joy-peak-mid-insulated-hooded-jacket-1982661/v12/p3.webp",
                    "colorId": "dark-sapphire"
                }

            ]
        },
        {
            "id": 7,
            "shopstyleId":964200571,
            "name": "Columbia Women's Rosie Run™ Insulated Jacket",
            "price": "7,572.20",
            "apiPrice":"4,686.00",
            "sizeTypes":["standard","plus"],
            "type":"jacket",
            "productWebSite":"https://www.columbia.com/p/womens-rosie-run-insulated-jacket-2007581.html",
            "imgUrl":"https://columbia.scene7.com/is/image/ColumbiaSportswear2/2007581_013_f_om?wid=768&hei=806&v=1700226314",
            "colors": [
                {
                    "id": "shark&white&black",
                    "name": "Shark, White, Black",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                       
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        }
                       
                    ]
                },
                {
                    "id": "white&aqua-haze&dark-stone",
                    "name": "White, Aqua Haze, Dark Stone",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                       
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "white&tradewinds-grey&cirrus-grey",
                    "name": "White, Tradewinds Grey, Cirrus Grey",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                       
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XXL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                }
            ],
            "images": [
                {
                    "filePath": "/assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v1/p1.webp",
                    "colorId": "shark&white&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v1/p2.webp",
                    "colorId": "shark&white&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v1/p3.webp",
                    "colorId": "shark&white&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v1/p4.webp",
                    "colorId": "shark&white&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v1/p5.webp",
                    "colorId": "shark&white&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v1/p6.webp",
                    "colorId": "shark&white&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v1/p7.webp",
                    "colorId": "shark&white&black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v1/p8.webp",
                    "colorId": "shark&white&black"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v2/p1.webp",
                    "colorId": "white&aqua-haze&dark-stone"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v2/p2.webp",
                    "colorId": "white&aqua-haze&dark-stone"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v2/p3.webp",
                    "colorId": "white&aqua-haze&dark-stone"
                },
               
                
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v3/p1.webp",
                    "colorId": "white&tradewinds-grey&cirrus-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v3/p2.webp",
                    "colorId": "white&tradewinds-grey&cirrus-grey"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v3/p3.webp",
                    "colorId": "white&tradewinds-grey&cirrus-grey"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v4/p1.webp",
                    "colorId": "night-wave&dusty-pink&bright-aqua"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v4/p2.webp",
                    "colorId": "night-wave&dusty-pink&bright-aqua"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v4/p3.webp",
                    "colorId": "night-wave&dusty-pink&bright-aqua"
                },
                

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v5/p1.webp",
                    "colorId": "dark-nocturnal&gumdrop&tippet"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v5/p2.webp",
                    "colorId": "dark-nocturnal&gumdrop&tippet"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-rosie-run-insulated-jacket-2007581/v5/p3.webp",
                    "colorId": "dark-nocturnal&gumdrop&tippet"
                }
            ]
        },     
        {
            "id": 8,
            "shopstyleId":941948908,
            "name": "Columbia Women's Kruser Ridge™ II Plush Softshell Jacket",
            "price": "5,790.86",
            "apiPrice":"2,480.00",
            "sizeTypes":["standard","plus"],
            "type":"jacket",
            "productWebSite":"https://www.columbia.com/p/womens-kruser-ridge-ii-plush-softshell-jacket-1864711.html",
            "imgUrl":"https://columbia.scene7.com/is/image/ColumbiaSportswear2/1864711_010_f?wid=768&hei=806&v=1700226314",
            "colors": [
                {
                    "id": "black",
                    "name": "Black",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "chalk",
                    "name": "Chalk",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value": "1X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "2X",
                            "sizeType": "plus"
                        },
                        {
                            "value": "3X",
                            "sizeType": "plus"
                        }
                    ]
                },
                {
                    "id": "dark-nocturnal",
                    "name": "Dark Nocturnal",
                    "sizeTypes": [
                        "standard"
                    ],
                    "sizes": [
                       
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "M",
                            "sizeType": "standard"
                        }
                    ]
                },
               
                {
                    "id": "dusty-pink-heather",
                    "name": "Dusty Pink Heather",
                    "sizeTypes": [
                        "standard"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        }
                    ]
                },
                {
                    "id": "beetroot-heather",
                    "name": "Beetroot Heather",
                    "sizeTypes": [
                        "standard",
                        "plus"
                    ],
                    "sizes": [
                        {
                            "value": "XS",
                            "sizeType": "standard"
                        },
                        {
                            "value": "S",
                            "sizeType": "standard"
                        },
                        {
                            "value": "L",
                            "sizeType": "standard"
                        },
                        {
                            "value": "XL",
                            "sizeType": "standard"
                        },
                        {
                            "value":"1X",
                            "sizeType":"plus"
                        },
                        {
                            "value":"2X",
                            "sizeType":"plus"
                        },
                        {
                            "value":"3X",
                            "sizeType":"plus"
                        }
                    ]
                }
            ],
            "images": [
                {
                    "filePath": "/assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v1/p1.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v1/p2.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v1/p3.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v1/p4.webp",
                    "colorId": "black"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v1/p5.webp",
                    "colorId": "black"
                },
                
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v2/p1.webp",
                    "colorId": "chalk"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v2/p2.webp",
                    "colorId": "chalk"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v2/p3.webp",
                    "colorId": "chalk"
                },
               
                
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v3/p1.webp",
                    "colorId": "dark-nocturnal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v3/p2.webp",
                    "colorId": "dark-nocturnal"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v3/p3.webp",
                    "colorId": "dark-nocturnal"
                },

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v4/p1.webp",
                    "colorId": "dusty-pink-heather"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v4/p2.webp",
                    "colorId": "dusty-pink-heather"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v4/p3.webp",
                    "colorId": "dusty-pink-heather"
                },
                

                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v5/p1.webp",
                    "colorId": "beetroot-heather"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v5/p2.webp",
                    "colorId": "beetroot-heather"
                },
                {
                    "filePath": "../../../../assets/img/colombia/products/womens-kruser-ridge-ii-plush-softshell-jacket-1864711/v5/p3.webp",
                    "colorId": "beetroot-heather"
                }
            ]
        }
    ]
}