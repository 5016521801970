import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BasketService } from '../../../../../src/app/shareds/services/basket.service';

@Injectable({providedIn: 'root'})
export class BasketCountService {

  private basketCount = new BehaviorSubject(0);
  currentBasketCount = this.basketCount.asObservable();

  constructor(private basketService:BasketService) { }

  updateCount(count: number) {
    this.basketCount.next(count);
    this.basketService.getBasketList()
  }

}