import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { BfmService } from "../../../../src/app/core/services/app/bfm.service";
import { default as data } from '../../../app/modules/colombia/data/products.json';
import { LocalStorageService } from "../../core/services/local-storage.service";

@Injectable({ providedIn: 'root' })
export class BasketService {
    products = data.data as any[]
    shopstyleIds: any[] = []
    basketList: any[] = []
    columbiaProducts: any[] = []
    otherProducts: any[] = []
    isThereColumbiaProductItemInBasket = new BehaviorSubject(false)
    isTherOtherProduct = new BehaviorSubject(false)
    constructor(private bfmService: BfmService, private localStorageService: LocalStorageService) {
        this.shopstyleIds = this.products.map(m => m.shopstyleId)
    }

    getShopstyleIds(): any[] {
        return this.shopstyleIds
    }

    getUserPrice(shopstyleId: string): number {
        let price = this.products.find(f => f.shopstyleId == Number(shopstyleId)).price
        return parseFloat(price.replace(/\,/g, ''))
    }

    getBasketList() {
        this.otherProducts = []
        this.columbiaProducts = []
        const authArg: string | null = this.localStorageService.getItem('auth');
        if (authArg !== null && authArg !== undefined) {
            this.bfmService.getBfmProductList().subscribe((data: any) => {
                if (data.Result === 200) {
                    this.basketList = data.Data
                    this.columbiaProducts = this.basketList.filter(f => this.shopstyleIds.includes(Number(f.ShopStyleId)))
                    this.otherProducts = this.basketList.filter(f => !this.shopstyleIds.includes(Number(f.ShopStyleId)))
                    this.isTherOtherProduct.next(this.otherProducts.length != 0)
                    this.isThereColumbiaProductItemInBasket.next(this.columbiaProducts.length != 0)
                }
                if (data.Result === 204) {
                    this.otherProducts = []
                    this.columbiaProducts = []
                    this.isThereColumbiaProductItemInBasket.next(this.columbiaProducts.length != 0)
                    this.isTherOtherProduct.next(this.otherProducts.length != 0)
                }
            })

        } else {
            this.getTempBasketItems();
        }
    }

    getTempBasketItems() {
        this.basketList = JSON.parse(this.localStorageService.getItem('tempBasket') || '{}');
        if (this.basketList !== null && this.basketList !== undefined) {
            this.columbiaProducts = this.basketList.filter(f => this.shopstyleIds.includes(Number(f.ShopStyleId)))
            this.isThereColumbiaProductItemInBasket.next(this.columbiaProducts.length != 0)
            this.otherProducts = this.basketList.filter(f => !this.shopstyleIds.includes(Number(f.ShopStyleId)))
            this.isTherOtherProduct.next(this.otherProducts.length != 0)
        }
    }
}